import { initializeCustomZod } from "./zod";
import { ZodError, z } from "zod";
import { Provider } from "@src/utils/model/constants";
import { External } from "@src/external/constants";

initializeCustomZod();

// future:
// how to add trace from another error?
// how to add more context?
// eg: what is not found / unauthenticated / unauthorized?
// message is a good start, but might not be enough

const zServerEventPayload = z.object({
  type: z.enum([
    "zod",
    "unknown",
    "payment",
    "not-found",
    "unauthenticated",
    "unauthorized",
    "missing-key",
    "openai/general",
    "http/bad-request",
    "http/timeout",
  ]),
  message: z.string(),
  stack: z.string().optional(),
});

export type ServerEventPayload = z.infer<typeof zServerEventPayload>;

export type ServerErrorType = ServerEventPayload["type"];

export class ServerError extends Error {
  type: ServerErrorType;
  static nonRetryable: ServerErrorType[] = [
    "zod",
    "not-found",
    "unauthenticated",
    "unauthorized",
    "missing-key",
    "http/bad-request",
    "http/timeout",
  ];

  constructor(type: ServerErrorType, message: string, stack?: string) {
    super(message);
    this.name = "ServerError";
    this.type = type;
    this.stack = stack ?? this.stack;
  }

  isRetryable() {
    return !ServerError.nonRetryable.includes(this.type);
  }

  toEvent(): ServerEventPayload {
    return {
      type: this.type,
      message: this.message,
      stack: this.stack,
    };
  }

  toString() {
    return JSON.stringify(this.toEvent());
  }

  static fromEvent(payload: ServerEventPayload) {
    return new ServerError(payload.type, payload.message, payload.stack);
  }

  toHTTPStatus() {
    switch (this.type) {
      case "not-found":
        return 404;

      case "unauthenticated":
      case "unauthorized":
        return 401;

      case "zod":
      case "http/bad-request":
        return 400;

      case "http/timeout":
        return 408;

      default:
        return 500;
    }
  }

  static fromString(payload: string) {
    try {
      return ServerError.fromEvent(
        zServerEventPayload.parse(JSON.parse(payload))
      );
    } catch (err) {
      return new ServerError("unknown", payload);
    }
  }

  static fromError(err: Error) {
    if (ServerError.isServerError(err)) {
      return err;
    }

    if (err instanceof ZodError) {
      return new ServerError(
        "zod",
        err.issues.map((i) => i.message).join("\n"),
        err.stack
      );
    }

    return new ServerError("unknown", err.message, err.stack);
  }

  static from(err: unknown) {
    if (err instanceof Error) {
      return ServerError.fromError(err);
    }

    if (typeof err === "string") {
      return ServerError.fromString(err);
    }

    return ServerError.fromString(String(err));
  }

  static isServerError(err: any): err is ServerError {
    return err.name === "ServerError";
  }

  static isOfType(
    type: ServerErrorType,
    err: any
  ): err is ServerError & { type: ServerErrorType } {
    return ServerError.isServerError(err) && err.type === type;
  }

  static notFound(message: string) {
    return new ServerError("not-found", message);
  }

  static isNotFound(err: any): err is ServerError {
    return ServerError.isOfType("not-found", err);
  }

  static unauthenticated(message: string) {
    return new ServerError("unauthenticated", message);
  }

  static isUnauthenticated(err: any): err is ServerError {
    return ServerError.isOfType("unauthenticated", err);
  }

  static unauthorized(message: string) {
    return new ServerError("unauthorized", message);
  }

  static isUnauthorized(err: any): err is ServerError {
    return ServerError.isOfType("unauthorized", err);
  }

  static missingKey(provider: Provider | External) {
    return new ServerError(
      "missing-key",
      `Please add your ${provider} key from the settings page.`
    );
  }

  static isMissingKey(err: any): err is ServerError & {
    type: "missing-key";
  } {
    return ServerError.isOfType("missing-key", err);
  }

  static generalOpenAIError(message: string) {
    try {
      const parsed = z
        .object({
          error: z.object({
            message: z.string(),
          }),
        })
        .parse(JSON.parse(message));

      return new ServerError("openai/general", parsed.error.message);
    } catch {}

    return new ServerError("openai/general", message);
  }

  static isGeneralOpenAIError(err: any): err is ServerError {
    return ServerError.isOfType("openai/general", err);
  }

  static payment(message: string) {
    return new ServerError(
      "payment",
      `Payment failed: ${message}. Please reach out to support@retune.so if you were charged.`
    );
  }

  static isPayment(err: any): err is ServerError {
    return ServerError.isOfType("payment", err);
  }

  static badRequest(message: string) {
    return new ServerError("http/bad-request", message);
  }

  static isBadRequest(err: any): err is ServerError {
    return ServerError.isOfType("http/bad-request", err);
  }

  static timeout(message: string) {
    return new ServerError("http/timeout", message);
  }

  static raise(err: unknown): never {
    throw ServerError.from(err);
  }
}
