import { ZodIssueCode, z } from "zod";

const shallowStringify = (obj: unknown, level = 2): string => {
  if (Array.isArray(obj)) {
    if (level <= 0) {
      return "[...]";
    }

    return `[${obj.map((v) => shallowStringify(v, level - 1)).join(", ")}]`;
  }

  if (obj instanceof Date) {
    return `Date(${obj.toISOString()})`;
  }

  if (typeof obj === "object" && obj !== null) {
    if (level <= 0) {
      return "{...}";
    }

    return `{${Object.entries(obj)
      .map(([k, v]) => `${k}: ${shallowStringify(v, level - 1)}`)
      .join(", ")}}`;
  }

  return JSON.stringify(obj);
};

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  const message = ctx.defaultError;

  const path = issue.path
    .map((p) =>
      typeof p === "number" || !/^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(p)
        ? `[${p}]`
        : `.${p}`
    )
    .join("");

  // note: this is a hack to get the value of the union discriminator, a bug in zod
  const value =
    issue.code === ZodIssueCode.invalid_union_discriminator
      ? ctx.data?.[issue.path.at(-1)!]
      : ctx.data;

  return { message: `$${path}=${shallowStringify(value)}: ${message}` };
};

export const initializeCustomZod = () => {
  z.setErrorMap(customErrorMap);
};
