"use client";

import { Provider as JotaiProvider } from "jotai/react";
import { WritableAtom, createStore } from "jotai/vanilla";
import React, { ComponentType, PropsWithChildren } from "react";

import { createStrictAtom } from "@src/utils/atom";
import {
  type TBrandData,
  type TChat,
  type TChatThread,
  type TEmbedding,
  type TSettings,
  type TUser,
} from "@src/resync/query";

type AnyWritableAtom = WritableAtom<unknown, any[], any>;

export const store = createStore();

export const Provider = ({ children }: { children: React.ReactNode }) => (
  <JotaiProvider store={store}>{children}</JotaiProvider>
);

export const withHydrate = <T extends Record<string, AnyWritableAtom>>(
  map: T
) => {
  const Component: ComponentType<
    PropsWithChildren<
      Omit<
        {
          [K in keyof T]?: T[K] extends WritableAtom<infer U, any, any>
            ? [U, number]
            : never;
        },
        "children"
      >
    >
  > = ({ children, ...values }) => {
    for (const [key, value] of Object.entries(values)) {
      const atom = map[key];

      if (!atom) {
        throw new Error(`Unknown atom for ${key}`);
      }

      store.set(atom, value[0], value[1]);
    }

    return <>{children}</>;
  };

  Component.displayName = "Hydrate";

  return Component;
};

export const userAtom = createStrictAtom<TUser>();

export const chatsAtom =
  createStrictAtom<Omit<TChat, "threads" | "connections">[]>();

export const chatAtom = createStrictAtom<
  Omit<TChat, "prompt"> & {
    prompt: undefined;
    explicitlyInjectedPrompt: string;
  }
>({
  shouldUpdate: (prev, next) =>
    prev.datasetId !== next.datasetId ||
    prev.threads.length !== next.threads.length ||
    prev.threads?.[0]?.id !== next.threads?.[0]?.id,
});

export const chatThreadAtom = createStrictAtom<TChatThread>({
  shouldUpdate: (prev, next) => prev.id !== next.id,
});

export const embeddingsAtom = createStrictAtom<TEmbedding[]>();

export const embeddingAtom = createStrictAtom<TEmbedding>({
  shouldUpdate: (prev, next) =>
    prev.datasetId !== next.datasetId &&
    prev.documents.length !== next.documents.length &&
    prev.documents[0]?.id !== next.documents[0]?.id,
});

export const brandAtom = createStrictAtom<TBrandData>();

export const settingsAtom = createStrictAtom<TSettings>();

export const HydrateClient = withHydrate({
  user: userAtom,

  chats: chatsAtom,
  chat: chatAtom,
  thread: chatThreadAtom,

  embeddings: embeddingsAtom,
  embedding: embeddingAtom,

  settings: settingsAtom,

  brand: brandAtom,
});
