import { useAtomValue, useSetAtom } from "jotai/react";
import { atom } from "jotai/vanilla";
import type { Draft } from "immer";
import { produce } from "immer";
import { ServerError } from "@src/app/errors";

export function createStrictAtom<T, U = Exclude<T, Function>>(opts?: {
  initialValue?: U;
  shouldUpdate?: (prev: U, next: U) => boolean;
}) {
  const valueAtom = atom<[U, number] | null>(
    opts?.initialValue ? [opts.initialValue, Date.now()] : null
  );
  const wrappedAtom = atom(
    (get) => {
      const value = get(valueAtom);

      if (value === null) {
        throw new Error("Value is null");
      }

      return value[0];
    },
    (
      _,
      set,
      update: U | ((draft: Draft<U>) => void),
      ts: number = Date.now()
    ) => {
      set(valueAtom, (value) => {
        const newValue =
          typeof update === "function"
            ? value === null
              ? ServerError.raise("Value is null")
              : produce(value[0], update as (draft: Draft<U>) => void)
            : update;

        if (
          value !== null &&
          !opts?.shouldUpdate?.(value[0], newValue) &&
          ts < value[1]
        ) {
          // console.debug(
          //   `[hydrate] ignoring update, previous ts: ${value[1]}, new ts: ${ts}. Previous value:`,
          //   value[0],
          //   "New value:",
          //   newValue,
          //   "shouldUpdate:",
          //   opts?.shouldUpdate?.(value[0], newValue)
          // );

          return value;
        }

        return [newValue, ts];
      });
    }
  );

  return Object.assign(wrappedAtom, {
    useValue: () => useAtomValue(wrappedAtom),
    useSetValue: () => useSetAtom(wrappedAtom),
  });
}
